.page {
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  background-color: #f4f4f4;
  min-width: 320px;
  flex: 1 0 auto;
}
.page__body {
  padding: 14px 0;
}
