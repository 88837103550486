.spin {
  display: inline-block;
  vertical-align: middle;
  animation: rotate 2s 0.05s linear infinite;
}
.spin .spin__icon {
  animation: dash 1.5s ease-in-out infinite;
}
.spin_align_center {
  position: fixed;
  top: 50%;
  left: 50%;
}
.spin_align_center.spin_size_s {
  margin-left: -6px;
  margin-top: -6px;
}
.spin_align_center.spin_size_m {
  margin-left: -12px;
  margin-top: -12px;
}
.spin_align_center.spin_size_l {
  margin-left: -30px;
  margin-top: -30px;
}
.spin_size_s {
  width: 24px;
  height: 24px;
}
.spin_size_m {
  width: 32px;
  height: 32px;
}
.spin_size_l {
  width: 60px;
  height: 60px;
}
.spin__icon {
  stroke: #4bb9df;
  stroke-width: 6;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 125;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 125;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 100, 125;
    stroke-dashoffset: -124;
  }
}
