.typo {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
.typo_text_super {
  font-size: 32px;
}
.typo_text_xl {
  font-size: 26px;
}
.typo_text_l {
  font-size: 24px;
}
.typo_text_m {
  font-size: 18px;
}
.typo_text_s {
  font-size: 15px;
}
.typo_text_xs {
  font-size: 12px;
}
.typo_line_super {
  line-height: 35px;
}
.typo_line_xl {
  line-height: 29px;
}
.typo_line_l {
  line-height: 23px;
}
.typo_line_m {
  line-height: 21px;
}
.typo_line_s {
  line-height: 15px;
}
.typo_weight_bold {
  font-weight: 700;
}
.typo_weight_medium {
  font-weight: 500;
}
