.user {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 100%;
  background-color: #fff;
  line-height: 40px;
  text-align: center;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 1px rgba(0, 0, 0, 0.03);
}
