.error {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;

  &__wrapper {
    padding: 0 40px;
    text-align: center;
    box-sizing: border-box;
    position: fixed;
    top: 44vh;
    transform: translateY(-50%);
  }
}
