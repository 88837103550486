@import (reference) '../../common/variables.less';

.footer {
  height: 56px;
  border: none;
  padding: 0;
  width: 100%;

  &_empty {
    height: auto;
  }

  &__wrapper {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: right;
  }

  &__toasts {
    padding: 0 @space-common;
    margin: 0;
    list-style-type: none;
    overflow: auto;
  }

  &__action {
    display: inline-block;
    outline: none;
    border: none;
    -webkit-appearance: none;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: @color-brand url('../../images/Add.svg') no-repeat center center;
    background-size: 24px 24px;
    margin: 0 @space-common @space-common;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.14);

    &:active {
      background-color: darken(@color-brand, 20%);
    }
  }
}

.toast-item {
  margin-bottom: 14px;
}
