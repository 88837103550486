.button {
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 0 25px;
  appearance: none;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;
}
.button_theme_normal {
  background-color: rgba(0, 0, 0, 0.05);
}
.button_theme_action {
  background-color: #4bb9df;
}
.button_theme_link {
  background-color: transparent;
}
.button_theme_danger {
  background-color: #FD363B;
}
.button_theme_action:active,
.button_theme_danger:active {
  background-color: #25a5d2;
}
.button_theme_normal:active {
  background-color: rgba(0, 0, 0, 0.15);
}
.button_size_m {
  height: 46px;
  line-height: 46px;
}
.button_size_l {
  height: 58px;
  line-height: 58px;
}
.button > .button__text {
  font-size: 16px;
}
.button_theme_action .button__text,
.button_theme_danger .button__text {
  color: #fff;
}
.button_theme_default .button__text {
  color: #000;
}
