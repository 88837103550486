@import (reference) '../common/variables.less';

@block: banner;

.@{block} {
  &__title {
    margin: 0;
    margin-bottom: 10px;
  }

  &__content {
    text-align: center;
    padding: 0 @space-common;
  }

  &__picture {
      margin: 0 -@space-common;
  }

  &__description {
    margin: 0;
    margin-bottom: 20px;
    line-height: 20px;
    color: @color-typo-secondary;
  }

  &__action-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
}
