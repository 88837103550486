.toast {
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  text-align: center;
  padding: 14px 30px 0;
  border-radius: 8px;
  box-shadow: 0 4px 16px 1px rgba(0, 0, 0, 0.04);
  height: 100%;
  overflow: auto;
}
.toast__message {
  color: #fff;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 14px;
}
.toast__action {
  border: none;
  box-shadow: none;
  color: #4bb9df;
  background-color: transparent;
  margin-bottom: 14px;
  padding: 0;
}
