.footer {
  height: 56px;
  border: none;
  padding: 0;
  width: 100%;
}
.footer_empty {
  height: auto;
}
.footer__wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: right;
}
.footer__toasts {
  padding: 0 14px;
  margin: 0;
  list-style-type: none;
  overflow: auto;
}
.footer__action {
  display: inline-block;
  outline: none;
  border: none;
  -webkit-appearance: none;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #4bb9df url('../../images/Add.svg') no-repeat center center;
  background-size: 24px 24px;
  margin: 0 14px 14px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}
.footer__action:active {
  background-color: #1e83a6;
}
.toast-item {
  margin-bottom: 14px;
}
