.header {
  flex: 0 0 auto;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.header_type_main {
  min-height: 96px;
  padding-top: 0;
  margin-top: 5px;
}
.header .support-button {
  margin-right: 14px;
}
.header_type_nested {
  height: auto;
  padding-top: 64px;
}
.header_type_nested .header__title {
  margin-bottom: 10px;
}
.header_type_nested .header__title:last-child {
  margin-bottom: 20px;
}
.header_type_nested .header__subtitle {
  margin-bottom: 17px;
}
.header__title-tag {
  margin: 0;
  flex: 1 1 auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.header__row {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.header__subtitle {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
}
