@import (reference) '../common/variables.less';

.toast {
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);
  width: 100%;
  text-align: center;
  padding: @space-common 30px 0;
  border-radius: 8px;
  box-shadow: 0 4px 16px 1px rgba(0, 0, 0, 0.04);
  height: 100%;
  overflow: auto;

  &__message {
    color: #fff;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: @space-common;
  }

  &__action {
    border: none;
    box-shadow: none;
    color: @color-brand;
    background-color: transparent;
    margin-bottom: @space-common;
    padding: 0;
  }
}
