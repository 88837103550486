@import (reference) '../common/variables.less';

.page {
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 @space-common;
    background-color: @color-page-bg;
    min-width: 320px;
    flex: 1 0 auto;

    &__body {
        padding: @space-common 0;
    }
}
