.banner__title {
  margin: 0;
  margin-bottom: 10px;
}
.banner__content {
  text-align: center;
  padding: 0 14px;
}
.banner__picture {
  margin: 0 -14px;
}
.banner__description {
  margin: 0;
  margin-bottom: 20px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
}
.banner__action-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
