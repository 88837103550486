@import (reference) '../../common/variables.less';

.header {
    flex: 0 0 auto;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid rgba(0, 0, 0, .05);

    &_type_main {
        min-height: 96px;
        padding-top: 0;
        margin-top: 5px;
    }

    .support-button {
        margin-right: @space-common;
    }

    &_type_nested {
        height: auto;
        padding-top: 64px;

        .header__title {
            margin-bottom: 10px;
        }

        .header__title:last-child {
            margin-bottom: 20px;
        }

        .header__subtitle {
            margin-bottom: 17px;
        }
    }

    &__title-tag {
        margin: 0;
        flex: 1 1 auto;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__row {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    &__subtitle {
        color: @color-typo-secondary;
        font-size: 14px;
    }
}
