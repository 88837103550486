@import (reference) '../common/variables.less';

.button {
  color: @color-typo-secondary;
  text-align: center;
  padding: 0 25px;
  appearance: none;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;

  &_theme {
    &_normal {
      background-color: rgba(0,0,0, .05);
    }

    &_action {
      background-color: @color-brand;
    }

    &_link {
      background-color: transparent;
    }

    &_danger {
      background-color: @color-danger;
    }
  }

  &_theme_action:active, &_theme_danger:active {
    background-color: darken(@color-brand, 10%);
  }

  &_theme_normal:active {
    background-color: rgba(0,0,0, .15)
  }

  &_size {
    &_m {
      height: 46px;
      line-height: 46px;
    }
    &_l {
      height: 58px;
      line-height: 58px;
    }
  }

  & > &__text {
    font-size: 16px;
  }

  &_theme_action &__text, &_theme_danger &__text {
    color: #fff;
  }

  &_theme_default &__text {
    color: #000
  }
}
