.typo {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;

  &_text{
    &_super{font-size: 32px;}
    &_xl{font-size: 26px;}
    &_l{font-size: 24px;}
    &_m{font-size: 18px;}
    &_s{font-size: 15px;}
    &_xs{font-size: 12px;}
  }

  &_line{
    &_super{line-height: 35px;}
    &_xl{line-height: 29px;}
    &_l{line-height: 23px;}
    &_m{line-height: 21px;}
    &_s{line-height: 15px;}
  }

  &_weight{
    &_bold{
      font-weight: 700;
    }
    &_medium{font-weight: 500;}
  }
}
