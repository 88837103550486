* {
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-focus-ring-color: transparent;
  -webkit-text-size-adjust: none;
}
input,
textarea {
  user-select: auto;
}
html {
  background-color: #f4f4f4;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
#root,
.router,
.router > div {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
