@import (reference) '../common/variables.less';

@size-s: 12px;
@size-m: 24px;
@size-l: 60px;

.spin {
  display: inline-block;
  vertical-align: middle;

  animation: rotate 2s .05s linear infinite;

  .spin__icon {
    animation: dash 1.5s ease-in-out infinite;
  }

  &_align_center {
      position: fixed;
      top: 50%;
      left: 50%;
  }

  &_align_center&_size_s {
      margin-left: -(@size-s / 2);
      margin-top: -(@size-s / 2);
  }

  &_align_center&_size_m {
    margin-left: -(@size-m / 2);
    margin-top: -(@size-m / 2);
  }

  &_align_center&_size_l {
    margin-left: -(@size-l / 2);
    margin-top: -(@size-l / 2);
  }

  &_size_s {
    width: 24px;
    height: 24px;
  }

  &_size_m {
    width: 32px;
    height: 32px;
  }

  &_size_l {
    width: 60px;
    height: 60px;
  }

  &__icon {
    stroke: @color-brand;
    stroke-width: 6;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 125;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 125;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 100, 125;
    stroke-dashoffset: -124;
  }
}
